import React, { useState, useEffect } from "react";
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import CryptoJS from "crypto-js";  // Import crypto-js
import { log } from '../utils/log';
import AssetSlider from "./AssetSlider";
import { Link as RouterLink } from 'react-router-dom';

axios.defaults.withCredentials = true;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fetchIp = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/ip`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json(); // Parse the response as JSON
    return data.ip; // Access the `ip` field from the parsed data
  } catch (error) {
    console.error('Error fetching IP:', error);
  }
};

const generateHashedUniqueId = async () => {
  let ip = await fetchIp();
  // Handle IPv6 localhost address
  if (ip === `::1`) {
    ip = `127.0.0.1`;
  }
  const ipParts = ip.split('.').slice(0, 3).join('.');
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const fingerprint = result.visitorId;

  // Log IP and device fingerprint before hashing
  log("IP Address:", ip);
  log("Device Fingerprint:", fingerprint);
  const uniqueId = `${ipParts}-${fingerprint}`;
  log("unique id:", uniqueId);
  
  // Hash the uniqueId using SHA-256
  const hash = CryptoJS.SHA256(uniqueId).toString();
  return hash;
};

const NetWorthForm = ({
  setRankInfo,
  setOpen,
  setForm,
  setAlreadySubmittedMessage,
  setTopRankings,
}) => {
  const [form, localSetForm] = useState({
    age: "",
    education: "",
    city: "",
    country: "",
    vehicles: 0,
    property: 0,
    stocks: 0,
    cash: 0,
    gold: 0,
    bankLoan: 0,
    creditCardDues: 0,
  });
  const [error, setError] = useState("");
  const [hashedUniqueId, setHashedUniqueId] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // State for checkbox

  const SubmitButton = styled(Button)`
    background-color: #ffb900;
    color: white;
    &:hover {
      background-color: #e0a800;
    }
  `;

  useEffect(() => {
    const getHashedUniqueId = async () => {
      fetchIp().then(ip => log("Fetched IP Address:", ip));
      const id = await generateHashedUniqueId();
      setHashedUniqueId(id);

      if (process.env.REACT_APP_ENV === "production") {
        try {
          const response = await axios.get(`${BASE_URL}/api/users?uniqueId=${id}`);
          if (response.data.exists) {
            setSubmitted(true);
            setAlreadySubmittedMessage("You have already submitted the information earlier.");
          }
        } catch (err) {
          console.error("Error checking unique ID", err);
        }
      }
    };
    getHashedUniqueId();
  }, [setAlreadySubmittedMessage]);

  useEffect(() => {
    if (["Mumbai", "Delhi", "Bangalore", "Chennai", "Kolkata"].includes(form.city)) {
      localSetForm({ ...form, country: "India" });
      setForm({ ...form, country: "India" });
    } else if (form.city === "Others") {
      localSetForm({ ...form, country: "" });
      setForm({ ...form, country: "" });
    }
  }, [form.city, setForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    localSetForm({ ...form, [name]: value });
    setForm({ ...form, [name]: value });
  };

  const handleSliderChange = (name) => (event, value) => {
    localSetForm({ ...form, [name]: value });
    setForm({ ...form, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked); // Update the checkbox state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.city || !form.education) {
      setError("Please fill in all required fields.");
      return;
    }

    const numericalFields = [
      "vehicles", "property", "stocks", "cash", "gold", "bankLoan", "creditCardDues"
    ];
    for (let field of numericalFields) {
      if (isNaN(form[field]) || form[field] === "") {
        setError(`Please enter a valid number for ${field}`);
        return;
      }
    }

    try {
      setError("");
      const userResponse = await axios.post(`${BASE_URL}/api/users`, { ...form, uniqueId: hashedUniqueId });
      const { isUpdate, updatedRankInfo } = userResponse.data;

      if (isUpdate) {
        setAlreadySubmittedMessage("You have already submitted the information earlier.");
      } else {
        setAlreadySubmittedMessage("");
      }
      setRankInfo(updatedRankInfo);
      setForm(form);
      setOpen(true);

      const rankingsResponse = await axios.get(`${BASE_URL}/api/top-rankings`);
      setTopRankings(rankingsResponse.data);
    } catch (err) {
      console.error("Error submitting form", err);
      if (err.response && err.response.status === 401) {
        setError("You need to log in to submit your information.");
      } else if (err.response && err.response.data) {
        setError(err.response.data.error);
      }
    }
  };

  return (
    <div className="form-container">
      <Typography variant="h2" gutterBottom>
        What's your Networth?
      </Typography>
      <Typography variant="body2">
        Net worth is a measure of an individual's financial health. It is the
        total value of everything a person owns (assets like property, vehicles,
        cash, investments, etc.) minus what they owe (liabilities like loans and
        credit card balances).
      </Typography>
      <Typography variant="body2">
        To know your net worth and how it ranks with others on this platform,
        please enter the following details.
      </Typography>
      <Typography variant="h4" color="error" gutterBottom>
        You only have one chance to submit the information. In case you submit
        wrong information, you will lose the opportunity to benchmark yourself.
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="age"
              label="Age"
              value={form.age}
              onChange={handleChange}
              required
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Education</InputLabel>
              <Select
                name="education"
                value={form.education}
                onChange={handleChange}
                label="Education"
              >
                {["MBA-IIM", "MBA", "Engineering", "Engineering-IIT", "Doctor", "Law", "Chartered Accountant", "Architecture", "Defence", "Others"].map((education) => (
                  <MenuItem key={education} value={education}>
                    {education}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>City</InputLabel>
              <Select
                name="city"
                value={form.city}
                onChange={handleChange}
                label="City"
              >
                {["Mumbai", "Delhi", "Bangalore", "Chennai", "Kolkata", "Others"].map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Country</InputLabel>
              <Select
                name="country"
                value={form.country}
                onChange={handleChange}
                label="Country"
                disabled={["Mumbai", "Delhi", "Bangalore", "Chennai", "Kolkata"].includes(form.city)}
              >
                {["India", "USA", "UK", "Canada", "Australia", "Others"].map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                                    ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Divider sx={{ marginY: 1 }} />
                              <Typography variant="h6" className="section-header">
                                Assets
                                <Typography variant="body2" component="span" style={{ fontSize: "0.8rem" }}>
                                  (Please use the slider to select the number closest to your asset value)
                                </Typography>
                              </Typography>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <AssetSlider
                                    label="Vehicles"
                                    value={form.vehicles}
                                    onChange={handleSliderChange("vehicles")}
                                    maxValue={2500}
                                    step1={2}
                                    step2={20}
                                    step3={40}
                                    breakpoint1={200}
                                    breakpoint2={1000}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <AssetSlider
                                    label="Property"
                                    value={form.property}
                                    onChange={handleSliderChange("property")}
                                    maxValue={50000}
                                    step1={3}
                                    step2={25}
                                    step3={500}
                                    breakpoint1={1000}
                                    breakpoint2={5000}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <AssetSlider
                                    label="Stocks/Mutual Funds"
                                    value={form.stocks}
                                    onChange={handleSliderChange("stocks")}
                                    maxValue={10000}
                                    step1={2}
                                    step2={20}
                                    step3={50}
                                    breakpoint1={500}
                                    breakpoint2={5000}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <AssetSlider
                                    label="Cash/FD/PPF"
                                    value={form.cash}
                                    onChange={handleSliderChange("cash")}
                                    maxValue={5000}
                                    step1={.5}
                                    step2={10}
                                    step3={25}
                                    breakpoint1={200}
                                    breakpoint2={1000}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <AssetSlider
                                    label="Gold/Silver/Diamonds"
                                    value={form.gold}
                                    onChange={handleSliderChange("gold")}
                                    maxValue={5000}
                                    step1={.5}
                                    step2={10}
                                    step3={25}
                                    breakpoint1={200}
                                    breakpoint2={1000}
                                  />
                                </Grid>
                              </Grid>
                              <Typography variant="h6" className="section-header">
                                <Divider sx={{ marginY: 1 }} /> Liabilities
                              </Typography>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <AssetSlider
                                    label="Bank Loan"
                                    value={form.bankLoan}
                                    onChange={handleSliderChange("bankLoan")}
                                    maxValue={5000}
                                    step1={2}
                                    step2={10}
                                    step3={25}
                                    breakpoint1={500}
                                    breakpoint2={2000}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <AssetSlider
                                    label="Credit Card Dues"
                                    value={form.creditCardDues}
                                    onChange={handleSliderChange("creditCardDues")}
                                    maxValue={5000}
                                    step1={0.25}
                                    step2={5}
                                    step3={25}
                                    breakpoint1={50}
                                    breakpoint2={1000}
                                  />
                                </Grid>
                              </Grid>
                              
                              {/* Consent checkbox with link to Data Privacy Policy */}
                              <Box mt={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography variant="body2">
                                      I agree to the{" "}
                                      <Link
                                        component={RouterLink}
                                        to="/data-privacy-policy"
                                        target="_blank"
                                      >
                                        Data Privacy Policy
                                      </Link>
                                    </Typography>
                                  }
                                />
                              </Box>
                    
                              <Box mt={2}>
                                <SubmitButton type="submit" fullWidth variant="contained" disabled={!isChecked}>
                                  Submit
                                </SubmitButton>
                              </Box>
                            </Box>
                            {error && <Typography color="error">{error}</Typography>}
                          </div>
                      );
                    };
                    
                    export default NetWorthForm;
                    
