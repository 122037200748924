import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Typography, Grid, Box } from "@mui/material";
import "../styles.css";  // Your global styles
import TopRankingsTable from "./TopRankingsTable";
import DialogBox from "./DialogBox";
import NetWorthForm from "./NetWorthForm";
import client from "../contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { log } from '../utils/log';
import SimpleSlider from "./SimpleSlider";  // Import the SimpleSlider component

function HomePage() {
  const [rankInfo, setRankInfo] = useState(null);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    age: "",
    education: "",
    city: "",
    country: "",
    vehicles: 0,
    property: 0,
    stocks: 0,
    cash: 0,
    gold: 0,
    bankLoan: 0,
    creditCardDues: 0,
  });
  const [alreadySubmittedMessage, setAlreadySubmittedMessage] = useState("");
  const [topRankings, setTopRankings] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);


  const cardData = [
    // Add your card data here
    {
      title: "1. Increase Savings",
      description: "Set a budget to track income and expenses. Cut unnecessary spending and save a portion of your income each month.",
      link: "/save",
      image: "/images/savings.jpg"
    },
    {
      title: "2. Boost Income",
      description: "Seek opportunities to increase earnings by asking for a raise, pursuing additional education, or starting a side hustle.",
      image: "/images/income.jpg"
    },
    {
      title: "3. Invest Wisely",
      description: "Educate yourself on investment options like stocks, bonds, real estate, and mutual funds.",
      link: "/invest",
      image: "/images/invest.jpg"
    },
    {
      title: "4. Manage Debt",
      description: "Focus on reducing high-interest debt. Pay off credit card balances and consider refinancing debts.",
      image: "/images/debt.jpg"
    },

    {
      title: "5. Protect Your Assets",
      description: "Ensure you have adequate insurance coverage for health, property, and life to safeguard against setbacks.",
      image: "/images/protect.jpg"
    },
    
    {
      title: "6. Learn from Successful People",
      description: "Read books, attend seminars, and follow financial experts to understand their strategies and mindset.",
      image: "/images/learn.jpg"
    },
  ];

  useEffect(() => {
    // Fetch top 10 rankings
    log("Fetching top rankings from:", `${process.env.REACT_APP_API_BASE_URL}/api/top-rankings`);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/top-rankings`)
      .then((response) => {
        log("Top Rankings Data: ", response.data);
        setTopRankings(response.data);
      })
      .catch((err) => console.error("Error fetching top rankings", err));
  }, []);

  useEffect(() => {
    // Fetch blog posts from Contentful
    client
      .getEntries({ content_type: "pageLanding" })
      .then((response) => {
        log("Contentful Data: ", response.items);
        setBlogPosts(response.items);
      })
      .catch((err) =>
        console.error("Error fetching blog posts from Contentful", err)
      );
  }, []);
  log(blogPosts); // Add this to inspect the blogPosts data

 

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className="rankings-container">
            <Typography variant="h4" gutterBottom>
              Top 10 Rankings
            </Typography>
            <TopRankingsTable data={topRankings} />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <NetWorthForm
            setRankInfo={setRankInfo}
            setOpen={setOpen}
            setForm={setForm}
            setAlreadySubmittedMessage={setAlreadySubmittedMessage}
            setTopRankings={setTopRankings} // Pass setTopRankings to NetWorthForm
          />
        </Grid>
        <Grid item xs={12}>
          <Box className="blog-container" sx={{ textAlign: "left", mt: 4 }}>
            {blogPosts.map((post) => (
              <Box key={post.sys.id} sx={{ mb: 3 }}>
                {post.fields.title && (
                  <Typography variant="h5">
                    {documentToReactComponents(post.fields.title)}
                  </Typography>
                )}
                {post.fields.body &&
                  documentToReactComponents(post.fields.body)}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      {/* Slider section */}
      {/* Integrate the SimpleSlider with card data */}
 <Box sx={{ mt: 4 }}>

        <SimpleSlider cardData={cardData} />
      </Box>
      <DialogBox
        open={open}
        handleClose={() => setOpen(false)}
        rankInfo={rankInfo}
        form={form}
        alreadySubmittedMessage={alreadySubmittedMessage}
      />
    </Container>
  );
};

export default HomePage;