import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../styles.css";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAuthenticated = !!localStorage.getItem("user");
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/logout`);
      localStorage.removeItem("user");
      navigate("/login");
    } catch (err) {
      console.error("Logout error:", err);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <div className="nav-container">
        <div className="logo">
          <img src="/favicon.ico" alt="Logo" className="logo-image" />
          <h1>Rank My Worth</h1>
        </div>
        <nav>
          <div className="menu-icon" onClick={toggleMenu}>
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </div>
          <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
            <li>
              <Link to="/" onClick={toggleMenu}>Home</Link>
            </li>
            <li>
              <Link to="/about" onClick={toggleMenu}>About</Link>
            </li>
            <li>
              <Link to="/invest" onClick={toggleMenu}>Invest</Link> {/* New Invest link */}
            </li>
            <li>
              <Link to="/save" onClick={toggleMenu}>Save</Link> {/* New Invest link */}
            </li>
            <li>
              <a href="https://www.fairranker.com" target="_blank" rel="noopener noreferrer" className="fair-ranker-link" onClick={toggleMenu}>FairRanker.com</a>
            </li>
            {isAuthenticated && (
              <>
                <li>
                  <Link to="/dashboard" onClick={toggleMenu}>Dashboard</Link>
                </li>
                <li>
                  <button onClick={handleLogout} className="nav-button logout-button">Logout</button>
                </li>
              </>
            )}
            {!isAuthenticated && (
              <li>
                <Link to="/login" onClick={toggleMenu}>Login</Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
