import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import HomePage from "./components/HomePage";
import Invest from "./components/Invest"; // Import the new Invest page
import Save from "./components/Save";
import CompoundInterestCalculator from "./components/CompoundInterestCalculator"; // Import the new component
import PowerOfCompounding from "./components/PowerOfCompounding"; // Import the new component
import BuyVsRent from "./components/BuyVsRent"; // Import the new component
import AuthPage from "./components/AuthPage";
import Dashboard from "./components/Dashboard"; // Create this component for user dashboard
import Dashboard1 from "./components/Dashboard1"; // Create this component for user dashboard
import TokenVerification from './components/TokenVerification';
import AboutPage from "./components/AboutPage";
import PrivateRoute from "./components/PrivateRoute";
import DataPrivacyPolicy from "./components/DataPrivacyPolicy";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import SavingPage from "./components/SavingPage";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import "./styles.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/invest" element={<Invest />} /> 
            <Route path="/save" element={<Save />} /> 
            <Route path="/compound-interest-calculator" element={<CompoundInterestCalculator />} />
            <Route path="/buy-vs-rent" element={<BuyVsRent />} />
            <Route path="/power-of-compounding" element={<PowerOfCompounding />} />
            <Route path="/login" element={<AuthPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/verify-token" element={<TokenVerification />} />
            <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
            <Route path="/dashboard1" element={<Dashboard1 />} />
            <Route path="/data-privacy-policy" element={<DataPrivacyPolicy />} />
            <Route path="/savingpage" element={<SavingPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
