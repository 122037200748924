import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Grid, Paper } from "@mui/material";
import { createClient } from "contentful";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Import the rich text renderer
import "./Invest.css"; // Create and link a CSS file for styling

// Initialize Contentful client using environment variables
const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

function Invest() {
const [content, setContent] = useState("");

useEffect(() => {
    // Replace '<your-contentful-entry-id>' with the actual entry ID from Contentful
    client
      .getEntry('2xd8owewspwjHE9zyFJ1B4')
      .then((entry) => {
        console.log("Fetched entry:", entry); // Log the entire entry object to see all fields
        setContent(entry.fields.shortDescription);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
      });
  }, []);

  return (
    <div className="invest-page">
       <Typography variant="h3" gutterBottom>
        Investing
      </Typography>
      <Typography variant="body1" className="invest-content">
        {content ? documentToReactComponents(content) : "Loading content..."}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card">
            <Typography variant="h5" gutterBottom>
              Compound Interest Calculator
            </Typography>
            <img src="/powerofcompounding.jpg" alt="Compounding Tool" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              Understand how your investments can grow over time with compound interest.
            </Typography>
            <Link to="/compound-interest-calculator" className="invest-tool-link">
              Try Now
            </Link>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card">
          <Typography variant="h5" gutterBottom>
              Power of Compounding
            </Typography>
            <img src="/powerofcompounding.jpg" alt="Compounding Tool" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              See how your investments grow over time with the power of compounding.
            </Typography>
            <Link to="/power-of-compounding" className="invest-tool-link">
              Try Now
            </Link>
          </Paper>
        </Grid>
        {/* Add more calculators/tools in a similar way */}
        <Grid item xs={12} md={4}>
         <Paper className="invest-tool-card">
         <Typography variant="h5" gutterBottom>
            Buy vs Rent
          </Typography>
         <img src="/buyvsrent.jpg" alt="Compounding Tool" className="tool-image" />
             <Typography variant="body2" gutterBottom>
            Compare whether buying an asset or renting it is better for you.
          </Typography>
          <Link to="/buy-vs-rent" className="invest-tool-link">
            Try Now
         </Link>
        </Paper>
        </Grid>
        {/* More tools can be added here */}
      </Grid>
    </div>
  );
}

export default Invest;
